import React ,{ useState,useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import axios from 'axios'
import { API } from '../utils/Constants';
import  SingleSearchForm from './SingleSearchForm';

//stateには、検索条件と検索状態を持つ
//useEffectにてRendering後に検索、毎回実施しないように、初期配列は、検索結果を保持する。
//useEffectにて検索結果を保持していても、

export  function BookingDetail(props) {
  const [booking, setBooking] = useState([]);
  const [dataExist, setDataExist] = useState(false);
  const [searchOrderId, setSearchOrderId] = useState("");

  const handleChange = event => {
    setSearchOrderId(event.target.value);
  };

  const handleClick = () =>{
    axios.get(API.ENDPOINT+'/v1/booking/'+searchOrderId,
    { headers: {'Content-Type': 'text/plain', 'x-api-key':API.X_API_KEY}, responseType: 'json' })
    .then(res => {
      setBooking(res.data)
      setDataExist(true);
    }).catch(error => {
      const {
        status,
        statusText
      } = error.response;
      setDataExist(false);
    });
    console.log("結果:"+JSON.stringify(booking))
  }

  // useEffect(() => {
    
  //   //clickSearchにて検索を実施、実施結果を表示する。
  //   {booking && booking.map((item) => ( 
  //     <div><TextField
  //     id="outlined-read-only-input"
  //     label="予約番号"
  //     defaultValue={item.予約番号}
  //     InputProps={{
  //       readOnly: true,
  //     }}
  //   />

  //   <TextField
  //     id="outlined-read-only-input"
  //     label="ステータス"
  //     defaultValue={item.ステータス}
  //     InputProps={{
  //       readOnly: true,
  //     }}
  //   /></div>
  //   ))}
  // }, [booking]);

  
  return <div>
    <Stack spacing={2} direction="row">
    <SingleSearchForm id='order_id' label='予約番号' placeholder='ABCDEFGH' handleClick={handleClick} handleChange={handleChange}/>

    {/* <TextField id="search-order-id" 
    label="予約番号" placeholder="ABCDEFGH" 
    variant="outlined" type="search" onChange={handleChange} 
    value={searchOrderId}/>

    <Button variant="contained" onClick={clickSearch}>検索</Button> */}
    </Stack>
    <br/>
    <hr/>検索結果 {!dataExist && <div>該当データが存在しません</div>}<br/><br/>
    {dataExist && <Stack spacing={2}>
      <TextField
      id="outlined-read-only-input"
      label="予約番号" variant="outlined"
      defaultValue=" "
      value={booking.予約番号}
      InputProps={{
        readOnly: true,
      }}
    />
    <TextField
      id="booking-result-status"
      // className={classes.textNormal}
      label="ステータス" variant="outlined"
      defaultValue=" "
      value={booking.ステータス}
      InputProps={{
        readOnly: true,
      }}
    />
    <TextField
      id="outlined-read-only-input"
      label="組織ID" variant="outlined"
      defaultValue=" "
      value={booking.組織ID}
      InputProps={{
        readOnly: true,
      }}
    />
    <TextField
      id="outlined-read-only-input"
      label="組織名" variant="outlined"
      defaultValue=" "
      value={booking.組織名}
      sx={{
        width: 300,
      }}
      InputProps={{
        readOnly: true,
      }}
    />
    <TextField
      id="outlined-read-only-input"
      label="OWNER'S BCLID" variant="outlined"
      defaultValue=" "
      value={booking.OWNER_BCL_ID}
      sx={{
        width: 300,
      }}
      InputProps={{
        readOnly: true,
      }}
    />
    <TextField
      id="outlined-read-only-input"
      label="プラン" variant="outlined"
      defaultValue=" "
      value={booking.プラン}
      sx={{
        width: 500,
      }}
      InputProps={{
        readOnly: true,
      }}
    />
    <TextField
      id="outlined-read-only-input"
      label="部屋番号" variant="outlined"
      defaultValue=" "
      value={booking.部屋番号}
      InputProps={{
        readOnly: true,
      }}
    />
    <TextField
      id="outlined-read-only-input"
      label="OTA" variant="outlined"
      defaultValue=" "
      value={booking.OTA}
      InputProps={{
        readOnly: true,
      }}
    />
    <TextField
      id="outlined-read-only-input"
      label="OTA予約番号" variant="outlined"
      defaultValue=" "
      value={booking.OTA予約番号}
      InputProps={{
        readOnly: true,
      }}
    />
      <TextField
      id="outlined-read-only-input"
      label="場所名" variant="outlined"
      defaultValue=" "
      value={booking.場所名}
      InputProps={{
        readOnly: true,
      }}
    />
    <TextField
      id="outlined-read-only-input"
      label="ユーザID" variant="outlined"
      defaultValue=" "
      value={booking.user_id}
      InputProps={{
        readOnly: true,
      }}
    />
    <TextField
      id="outlined-read-only-input"
      label="メールアドレス" variant="outlined"
      defaultValue=" "
      style= {{width: 300}} 
      value={booking.contact_email}
      InputProps={{
        readOnly: true,
      }}
    />
    <TextField
      id="outlined-read-only-input"
      label="金額" variant="outlined"
      defaultValue=" "
      value={booking.order_money}
      InputProps={{
        readOnly: true,
      }}
    />
    <TextField
      id="outlined-read-only-input"
      label="消費税" variant="outlined"
      defaultValue=" "
      value={booking.order_tax_money}
      InputProps={{
        readOnly: true,
      }}
    />
    <TextField
      id="outlined-read-only-input"
      label="discount_money" variant="outlined"
      defaultValue=" "
      value={booking.discount_money}
      InputProps={{
        readOnly: true,
      }}
    />
    <TextField
      id="outlined-read-only-input"
      label="pay_money" variant="outlined"
      defaultValue=" "
      value={booking.pay_money}
      InputProps={{
        readOnly: true,
      }}
    />
    <TextField
      id="outlined-read-only-input"
      label="チェックイン" variant="outlined"
      defaultValue=" "
      value={booking.チェックイン}
      InputProps={{
        readOnly: true,
      }}
    />
    <TextField
      id="outlined-read-only-input"
      label="チェックアウト" variant="outlined"
      defaultValue=" "
      value={booking.チェックアウト}
      InputProps={{
        readOnly: true,
      }}
    />
    <TextField
      id="outlined-read-only-input"
      label="チェックイン可能" variant="outlined"
      defaultValue=" "
      value={booking.チェックイン可能}
      sx={{
        width: 300,
      }}
      InputProps={{
        readOnly: true,
      }}
    />
    <TextField
      id="outlined-read-only-input"
      label="チェックアウト期限" variant="outlined"
      defaultValue=" "
      value={booking.チェックアウト期限}
      sx={{
        width: 300,
      }}
      InputProps={{
        readOnly: true,
      }}
    />
    <TextField
      id="outlined-read-only-input"
      label="uid" variant="outlined"
      defaultValue=" "
      value={booking.uid}
      sx={{
        width: 300,
      }}
      InputProps={{
        readOnly: true,
      }}
    />
    <TextField
      id="outlined-read-only-input"
      label="act" variant="outlined"
      defaultValue=" "
      value={booking.act}
      InputProps={{
        readOnly: true,
      }}
    />
    <TextField
      id="outlined-read-only-input"
      label="Customer's BCL_ID" variant="outlined"
      defaultValue=" "
      value={booking.CUSTOMER_BCL_ID}
      sx={{
        width: 300,
      }}
      InputProps={{
        readOnly: true,
      }}
    />
    <TextField
      id="outlined-read-only-input"
      label="name" variant="outlined"
      defaultValue=" "
      value={booking.name}
      InputProps={{
        readOnly: true,
      }}
    />
    <TextField
      id="outlined-read-only-input"
      label="email" variant="outlined"
      defaultValue=" "
      value={booking.email}
      InputProps={{
        readOnly: true,
      }}
    />
    </Stack>
    }</div>;
}