import React ,{ useState } from 'react';

import axios from 'axios'
import { API } from '../utils/Constants';
import { DataGrid } from '@mui/x-data-grid';
import  SingleSearchForm from './SingleSearchForm';

//stateには、検索条件と検索状態を持つ
//useEffectにてRendering後に検索、毎回実施しないように、初期配列は、検索結果を保持する。
//useEffectにて検索結果を保持していても、


const columns = [
  {
    field: 'phone',
    headerName: 'BCL_ID',
    width: 150,
  },
  {
    field: 'name',
    headerName: 'ユーザ名',
    width: 200,
  },
  {
    field: 'email',
    headerName: 'email',
    width: 200,
  },
  {
    field: 'uid',
    headerName: 'uid',
    width: 220,
  },
  {
    field: 'user_created_at',
    headerName: 'ユーザ登録日時',
    width: 200,
  },
  {
    field: 'org_id',
    headerName: '組織ID',
    width: 220,
  },
  {
    field: 'org_name',
    headerName: '組織名',
    width: 400,
  },
  {
    field: 'role_name',
    headerName: 'ロール',
    width: 200,
  },
  {
    field: 'service_units',
    headerName: 'LaaP契約数',
    width: 120,
  },
  {
    field: 'used_units',
    headerName: 'LaaP利用数',
    width: 120,
  },
  {
    field: 'device_count',
    headerName: 'デバイス数',
    width: 120,
  },
  {
    field: 'org_del_flag',
    headerName: '組織削除フラグ',
    width: 120,
  },
  {
    field: 'web_url',
    headerName: 'web_url',
    width: 320,
  },
  {
    field: 'org_state',
    headerName: '組織ステータス',
    width: 120,
  },
  {
    field: 'org_category',
    headerName: '組織カテゴリ',
    width: 120,
  },
];

export  function OrgList(props) {
  const [orgList, setOrgList] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");

  const handleChange = event => {
    setSearchKeyword(event.target.value);
  };

  const handleClick = event => {
    axios.get(API.ENDPOINT+'/v1/org/phone/'+searchKeyword,
    { headers: {'Content-Type': 'text/plain', 'x-api-key':API.X_API_KEY}, responseType: 'json' })
    .then(res => {
      setOrgList(res.data)
    }).catch(err => {
      console.log(err.response.data)})
      setOrgList([])
  };

  return (
    <div style={{ height: 650, width: '100%' }}>
      <SingleSearchForm id='bcl_id' label='BCLID(電話番号)' placeholder='09012345678' handleClick={handleClick} handleChange={handleChange}/>
      <DataGrid
        getRowId={(row) => row.org_id}
        rows={orgList}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        checkboxSelection
        disableSelectionOnClick
      />
    </div>
  );
}