import React ,{ useState,useEffect } from 'react';
import Stack from '@mui/material/Stack';

import axios from 'axios'
import { API } from '../utils/Constants';
import  SingleSearchForm from './SingleSearchForm';
import { DataGrid } from '@mui/x-data-grid';

//stateには、検索条件と検索状態を持つ
//useEffectにてRendering後に検索、毎回実施しないように、初期配列は、検索結果を保持する。
//useEffectにて検索結果を保持していても、

export  function OrgDetail(props) {
  const [orgList, setOrgList] = useState([]);
  const [dataExist, setDataExist] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchOrgName, setSearchOrgName] = useState("");



  const columns = [
    {
      field: 'org_id',
      headerName: '組織ID',
      width: 220,
    },
    {
      field: 'org_name',
      headerName: '組織名',
      width: 200,
    },
    {
      field: 'OWNER_BCL_ID',
      headerName: 'OWNER_BCL_ID',
      width: 180,
    },
    {
      field: 'target_name',
      headerName: 'target_name',
      width: 200,
    },
    {
      field: 'org_category',
      headerName: 'カテゴリ',
      width: 80,
    },
    {
      field: 'del_flag',
      headerName: '削除フラグ',
      width: 120,
    },
  ];
  
  
  const handleChange = event => {
    setSearchOrgName(event.target.value);
  };



  const handleClick = () =>{
    axios.get(API.ENDPOINT+'/v1/org/search?org_name='+searchOrgName,
    { headers: {'Content-Type': 'text/plain', 'x-api-key':API.X_API_KEY}, responseType: 'json' })
    .then(res => {
      console.log(res.data)
      setOrgList(res.data)
      setDataExist(true);
    }).catch(error => {
      const {
        status,
        statusText
      } = error.response;
      setDataExist(false);
      setOrgList([]);
      setErrorMessage(error.response.data["error_message"])
    });
  }


  
  return (
  <div style={{ height: 650, width: '100%' }}>
    <Stack spacing={2} direction="row">
    <SingleSearchForm id='orgName' label='組織名（部分一致)' placeholder='ABCDEFGH' handleClick={handleClick} handleChange={handleChange}/>
    </Stack>
    <br/>
    <DataGrid
        getRowId={(row) => row.org_id}
        rows={orgList}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[1]}
        checkboxSelection
        disableSelectionOnClick
      />
</div>
);

}