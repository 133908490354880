import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';


function SingleSearchForm(props) {
    return (<Stack spacing={2} direction="row">
        <TextField id={props.id} label={props.label} variant="outlined" type="search" placeholder={props.placeholder}  onChange={props.handleChange}/>
        <Button variant="contained" onClick={props.handleClick}>検索</Button>
    </Stack>);
}


export default SingleSearchForm;