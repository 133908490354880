import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {useNavigate} from 'react-router-dom'
import { useAuth0 } from "@auth0/auth0-react";

export default function BasicMenu() {
  const history = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const {user,logout} = useAuth0();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () =>{
    logout('/');
  }

  const handleToSearchBooking = () =>{
    history('/searchBooking');
  }

  const handleToSearchUsersBookingList = () =>{
    history('/searchBookingList');
  }

  const handleToSearchSMSLog = () =>{
    history('/SMSLogList');
  }

  const handleToSearchOrgainzaiton = () =>{
    history('/OrganizationList');
  }
  const handleToSearchOrgByName = () =>{
    history('/searchOrg');
  }

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        ツール選択
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleToSearchBooking}>予約検索</MenuItem>
        <MenuItem onClick={handleToSearchUsersBookingList}>ユーザ予約一覧</MenuItem>
        <MenuItem onClick={handleToSearchSMSLog}>SMS送信実績</MenuItem>
        <MenuItem onClick={handleToSearchOrgainzaiton}>組織検索</MenuItem>
        <MenuItem onClick={handleToSearchOrgByName}>組織検索(部分一致)</MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>&nbsp;ようこそ {user.name}さん。
      <hr/><br/>
    </div>
  );
}