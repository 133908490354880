import React from 'react';
import BasicMenu from '../components/Menu';
import { BookingDetail } from '../components/BookingDetail';
import { Grid } from '@mui/material';
import { useAuth0 } from "@auth0/auth0-react";


export function SearchBooking() {
  const {isAuthenticated, isLoading,loginWithRedirect } = useAuth0();
  if (isLoading) {
    return <div>Loading ...</div>;
  }
  if(!isAuthenticated){
    loginWithRedirect()
  }


  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
      <BasicMenu/>
      </Grid>
      <Grid item xs={1}>
      </Grid>
      <Grid item xs={10}>
    <BookingDetail />
    </Grid>
    </Grid>
  );
}
