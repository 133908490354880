import React ,{ useState,useEffect } from 'react';

import axios from 'axios'
import { API } from '../utils/Constants';
import { DataGrid } from '@mui/x-data-grid';
import  SingleSearchForm from './SingleSearchForm';

//stateには、検索条件と検索状態を持つ
//useEffectにてRendering後に検索、毎回実施しないように、初期配列は、検索結果を保持する。
//useEffectにて検索結果を保持していても、


const columns = [
  {
    field: 'request_time',
    headerName: 'request_time',
    width: 160,
  },
  {
    field: 'response_time',
    headerName: 'response_time',
    width: 160,
  },
  {
    field: 'sms_content',
    headerName: 'sms_content',
    width: 320,
  },
  {
    field: 'req_channel',
    headerName: 'req_channel',
    width: 120,
  },
  {
    field: 'resp_code',
    headerName: 'resp_code',
    width: 120,
  },
];

export  function SMSLog(props) {
  const [smsLog, setSMSLog] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");

  const handleChange = event => {
    setSearchKeyword(event.target.value);
  };

  const handleClick = event => {
    axios.get(API.ENDPOINT+'/v1/smslog/'+searchKeyword,
    { headers: {'Content-Type': 'text/plain', 'x-api-key':API.X_API_KEY}, responseType: 'json' })
    .then(res => {
      setSMSLog(res.data)
    }).catch(err => {
      console.log(err.response)})
      setSMSLog([])
  };

  return (
    <div style={{ height: 400, width: '100%' }}>
      <SingleSearchForm id='bcl_id' label='BCLID(電話番号)' placeholder='08012345678' handleClick={handleClick} handleChange={handleChange}/>
      <DataGrid
        getRowId={(row) => row.request_time}
        rows={smsLog}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
      />
    </div>
  );
}