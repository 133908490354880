import React from "react";
import "./App.css";
import { Route, Routes } from 'react-router-dom';
import Dashboard from "./pages/Dashboard";
import {SearchBooking} from "./pages/Booking";
import {BookingListByPhone} from "./pages/BookingListByPhone";
import { SMSLogList } from "./pages/SMSLogList";
import { OrganizationList } from "./pages/OrganizationList";
import { SearchOrganization } from "./pages/SearchOrganizaiton";

function App() {

  return (
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="searchBooking" element={<SearchBooking label="予約検索" />} />
        <Route path="searchBookingList" element={<BookingListByPhone label="予約一覧" />} />
        <Route path="SMSLogList" element={<SMSLogList label="SMS送信実績" />} />
        <Route path="OrganizationList" element={<OrganizationList label="組織検索" />} />
        <Route path="searchOrg" element={<SearchOrganization label="組織検索（部分一致)" />} />
      </Routes>
  );
}

export default App;